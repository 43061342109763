import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DialogBaseComponent } from '../dialog-base'

@Component({
  selector: 'navix-dialog-form-placeholder',
  standalone: true,
  imports: [CommonModule, DialogBaseComponent],
  templateUrl: './dialog-form-placeholder.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogFormPlaceholderComponent {
  private columnSpanClasses = [
    'col-span-12',
    'col-span-6',
    'col-span-4',
    'col-span-3'
  ]

  @Input() class: string = 'max-w-xl'
  @Input() rows: number = 5
  @Input() columns: { index: number; columns: 1 | 2 | 3 | 4 }[] = []

  get _rowList(): { columns: number; columnsList: unknown[] }[] {
    const resultRows = Array(this.rows).fill({
      columns: 1,
      columnsList: Array(1)
    })
    this.columns.forEach(({ index, columns }) => {
      resultRows[index] = { columns, columnsList: Array(columns) }
    })
    return [...resultRows]
  }

  getColSpanClass(numberOfColumns: number) {
    return this.columnSpanClasses[numberOfColumns - 1]
  }
}
