<section
  class="col-span-2 bg-white custom-details rounded-none h-full flex flex-col w-full border">
  <header
    class="text-xs after:text-icon-default after:left-5 uppercase font-medium p-4">
    <div class="flex flex-nowrap justify-between items-center">
      <ng-container *ngTemplateOutlet="customTitleLeft ?? defaultEmpty" />
      <ng-container *ngTemplateOutlet="customTitleCenter ?? defaultEmpty" />
      <ng-container *ngTemplateOutlet="customTitleRight ?? defaultEmpty" />
    </div>
  </header>
  <div class="px-4 h-[inherit] flex flex-col gap-2">
    <ng-container *ngTemplateOutlet="customBodyTemplate" />
    <ng-container *ngTemplateOutlet="customFooterTemplate ?? defaultEmpty" />
  </div>
</section>

<ng-template #defaultEmpty></ng-template>
