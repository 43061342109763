import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  booleanAttribute,
  input
} from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'navix-accordion',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './accordion.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccordionComponent {
  useFullHeight = input(false, { transform: booleanAttribute })
  @Input({
    transform: booleanAttribute
  })
  open = true

  @Output()
  openChange = new EventEmitter<boolean>()

  @Input() title = ''
  @ContentChild('body')
  customBodyTemplate!: TemplateRef<unknown>

  @ContentChild('titleRight')
  customTitleRight?: TemplateRef<unknown>

  @ContentChild('titleLeft')
  customTitleLeft?: TemplateRef<unknown>
}
