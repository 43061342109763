<div class="h-full grid grid-rows-[minmax(0,_1fr),_min-content]">
  <div class="bg-[#f3f5f9]">
    <ng-container *ngTemplateOutlet="customBodyTemplate" />
  </div>
  <footer class="p-4">
    <ng-container
      *ngTemplateOutlet="customFooterTemplate || defaulFooterTemplate">
    </ng-container>
  </footer>
</div>
<ng-template #defaulFooterTemplate>
  <div class="flex gap-2 justify-end">
    <button
      type="button"
      class="btn btn-outline-gray-500 w-24"
      id="cancel-btn"
      (click)="onCancel()">
      Cancel
    </button>
    <button type="submit" class="btn btn-main w-24" id="save-btn">Save</button>
  </div>
</ng-template>
