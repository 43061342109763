/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef
} from '@angular/core'

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'navix-header-actions',
  templateUrl: './header-actions.component.html',
  styleUrls: ['./header-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderActionsComponent {
  @ContentChild('rightTemplate')
  customRightActionButtonsTemplate: TemplateRef<any> | null = null
  @ContentChild('leftTemplate')
  customLeftActionButtonsTemplate: TemplateRef<any> | null = null

  @Input() loading = false
}
