<ng-container *ngIf="!loading; else loader">
  <div
    *ngIf="['full', 'left'].includes(displayMode)"
    class="flex flex-row items-center gap-x-2 flex-wrap">
    <p class="whitespace-nowrap">Rows per page:</p>
    <select
      class="txt-subtitle border-transparent bg-transparent focus:border-transparent focus:shadow-none focus:ring-0"
      id="pages"
      name="pages"
      (change)="onChangeRowsPerPage($event)">
      <option
        [value]="option"
        *ngFor="let option of itemsPerPageOptions"
        [selected]="option === itemsPerPage">
        {{ option }}
      </option>
    </select>
    @if (displayLeftPageCount()) {
      <span>•</span>
      <span class="px-2">{{ paginationLabel }}</span>
    }
  </div>

  <div
    *ngIf="['full', 'right'].includes(displayMode)"
    class="flex flex-wrap items-center gap-x-1 justify-end">
    <button
      id="pagination-bar-previous-page"
      class="text-gradient disabled:opacity-50"
      (click)="onChangePreviousPage()"
      [disabled]="!canGoToPreviousPage">
      <ng-icon name="heroChevronLeft" size="15" />
    </button>

    <div class="flex items-center">
      <ng-container *ngFor="let item of pageElements; index as i">
        <span class="elipsis" *ngIf="i === pagesCount - 2 && !item.visible">
          ...
        </span>

        <button
          class="page"
          [attr.id]="'pagination-bar-page-' + item.value"
          [class.visible]="item.visible"
          [attr.aria-selected]="_page === i + 1"
          (click)="changePage(item.value)">
          {{ item.value }}
        </button>
        <span class="elipsis" *ngIf="i === 1 && !item.visible"> ... </span>
      </ng-container>
    </div>
    <button
      id="pagination-bar-next-page"
      class="text-gradient disabled:opacity-50"
      (click)="onChangeNextPage()"
      [disabled]="!canGoToNextPage">
      <ng-icon name="heroChevronRight" size="15" />
    </button>
    <span>•</span>
    <form
      #form="ngForm"
      [ngFormOptions]="{ updateOn: 'submit' }"
      (ngSubmit)="submitPage()">
      <div class="flex gap-2 items-center txt-subtitle px-1">
        <span>Go to Page:</span>
        <input
          [(ngModel)]="pageFormModel"
          name="page"
          type="number"
          class="form-control txt-subtitle mt-0 py-0 px-2 w-12" />
        <span>/ {{ pagesCount }}</span>
      </div>
    </form>
  </div>
</ng-container>

<ng-template #loader>
  <div class="flex flex-row items-center gap-x-2 animate-pulse pb-2">
    <div class="bg-slate-200 w-24 h-5 rounded-md"></div>
    <div class="bg-slate-200 w-16 h-5 rounded-md"></div>
  </div>
  <div class="flex flex-row justify-end items-center gap-x-2 animate-pulse">
    <div class="bg-slate-200 w-24 h-5 rounded-md"></div>
    <div class="bg-slate-200 w-5 h-5 rounded-full"></div>
    <div class="bg-slate-200 w-5 h-5 rounded-full"></div>
  </div>
</ng-template>
<ng-template #elipsis>
  <span class="elipsis">...</span>
</ng-template>
