import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  booleanAttribute,
  input
} from '@angular/core'
import { FormsModule, NgForm } from '@angular/forms'
import { NgIconComponent, provideIcons } from '@ng-icons/core'
import { heroChevronLeft, heroChevronRight } from '@ng-icons/heroicons/outline'

@Component({
  standalone: true,
  selector: 'navix-pagination-bar',
  templateUrl: './pagination-bar.component.html',
  imports: [CommonModule, FormsModule, NgIconComponent],
  providers: [provideIcons({ heroChevronLeft, heroChevronRight })],
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['pagination-bar.component.scss']
})
export class PaginationBarComponent implements OnInit, OnChanges {
  private readonly VISIBLE_PAGES_COUNT = 5 as const
  @HostBinding('class')
  get class() {
    return `grid grid-cols-${this.displayMode === 'full' ? 2 : 1} text-sm`
  }
  @ViewChild(NgForm) formDir!: NgForm

  @Input() displayMode: 'full' | 'right' | 'left' = 'full'
  @Input() page: number = 1
  @Output() pageChange = new EventEmitter<number>()

  @Input({ required: true }) itemsPerPage!: number
  @Output() itemsPerPageChanged = new EventEmitter<number>()

  @Input({ transform: booleanAttribute }) loading?: boolean = false
  @Input() itemsPerPageOptions: number[] = []
  @Input({ required: true }) itemsCount!: number

  displayLeftPageCount = input(true, {
    transform: booleanAttribute
  })
  @Output() pageChanged = new EventEmitter<number>()

  protected _page = 1
  protected pageFormModel = 1
  get pagesCount() {
    return Math.ceil(this.itemsCount / this.itemsPerPage)
  }

  get paginationLabel() {
    const paginationEdge = this._page * this.itemsPerPage
    const begin = this.itemsPerPage * (this._page - 1) + 1
    const end =
      paginationEdge >= this.itemsCount ? this.itemsCount : paginationEdge
    return `${begin} - ${end} of ${this.itemsCount}`
  }

  get canGoToNextPage() {
    return this._page + 1 <= this.pagesCount
  }

  get canGoToPreviousPage() {
    return this._page - 1 > 0
  }

  get pageElements() {
    const maxPageNumber = this.pagesCount
    return Array(maxPageNumber)
      .fill({ visible: false, value: 0 })
      .map((item, i) => ({ ...item, value: i + 1 }))
      .map(item => {
        const halfOfMaximun = Math.floor(this.VISIBLE_PAGES_COUNT / 2)
        const restOfTopBoundary = Math.max(
          this._page + halfOfMaximun - maxPageNumber,
          0
        )
        const restOfBottomBoundary = Math.max(
          (this._page - (halfOfMaximun + 1)) * -1,
          0
        )
        const isOnTopBoundary =
          item.value <= this._page + (halfOfMaximun + restOfBottomBoundary)

        const isOnBottomBoundary =
          item.value >= this._page - (halfOfMaximun + restOfTopBoundary)

        return {
          ...item,
          visible: isOnBottomBoundary && isOnTopBoundary
        }
      })
  }

  ngOnInit(): void {
    if (!this.itemsPerPageOptions.includes(this.itemsPerPage)) {
      this.itemsPerPage = this.itemsPerPageOptions[0]
      this.itemsPerPageChanged.emit(this.itemsPerPage)
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const initialPageChange = changes['page']
    if (initialPageChange !== undefined) {
      const newPage = Number(initialPageChange.currentValue)
      this.internalChangePage(newPage)
    }
  }

  submitPage() {
    let newPage: number = this.formDir.value.page
    newPage = this.getCuratedPage(newPage)
    this.changePage(newPage)
    this.formDir.resetForm({ page: newPage })
  }

  private getCuratedPage(newPage: number) {
    if (newPage < 1) return 1
    if (newPage > this.pagesCount) return this.pagesCount
    return newPage
  }

  onChangeRowsPerPage(event: Event) {
    const {
      target: { value }
    } = event as Event & { target: HTMLSelectElement }
    this.itemsPerPage = Number(value)
    this.changePage(1)
    this.itemsPerPageChanged.emit(this.itemsPerPage)
  }

  onChangePreviousPage() {
    this.pageChanged.emit(--this._page)
  }

  onChangeNextPage() {
    this.pageChanged.emit(++this._page)
  }
  changePage(newPage: number) {
    this._page = newPage
    this.pageFormModel = newPage
    this.pageChange.emit(newPage)
    this.pageChanged.emit(newPage)
  }

  internalChangePage(newPage: number) {
    this._page = newPage
    this.pageFormModel = newPage
  }
}
