import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  TemplateRef
} from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'navix-block',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlockComponent {
  @ContentChild('titleLeft')
  customTitleLeft?: TemplateRef<unknown>

  @ContentChild('titleCenter')
  customTitleCenter?: TemplateRef<unknown>

  @ContentChild('titleRight')
  customTitleRight?: TemplateRef<unknown>

  @ContentChild('body')
  customBodyTemplate!: TemplateRef<unknown>

  @ContentChild('footer')
  customFooterTemplate!: TemplateRef<unknown>
}
