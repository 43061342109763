import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'navix-field-placeholder',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './field-placeholder.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldPlaceholderComponent {
  @Input() rows = 1

  get repeatList() {
    return Array(this.rows).fill(0)
  }
}
