import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay'
import { ComponentPortal } from '@angular/cdk/portal'
import {
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  inject
} from '@angular/core'

import { TooltipComponent } from './tooltip.component'

@Directive({ selector: '[navixTooltip]', standalone: true })
export class TooltipDirective implements OnInit, OnDestroy {
  private readonly overlay = inject(Overlay)
  private readonly overlayPositionBuilder = inject(OverlayPositionBuilder)
  private readonly elementRef = inject(ElementRef)

  @Input('navixTooltip') text: string | string[] = ''
  @Input('clickable') clickable = false
  visible = false
  @HostListener('mouseenter')
  show() {
    if (this.text && !this.clickable) {
      const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(
        new ComponentPortal(TooltipComponent)
      )
      tooltipRef.instance.textList =
        typeof this.text === 'string' ? [this.text] : this.text
    }
  }

  @HostListener('mouseleave')
  hideOnMouseLeave() {
    if (this.clickable) {
      this.visible = false
    }
    this.overlayRef.detach()
  }
  @HostListener('click')
  hide() {
    if (!this.clickable) {
      this.overlayRef.detach()
    } else {
      this.visible = !this.visible
      if (this.visible) {
        const tooltipRef: ComponentRef<TooltipComponent> =
          this.overlayRef.attach(new ComponentPortal(TooltipComponent))
        tooltipRef.instance.textList =
          typeof this.text === 'string' ? [this.text] : this.text
      } else {
        this.overlayRef.detach()
      }
    }
  }
  private overlayRef!: OverlayRef

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder
      .flexibleConnectedTo(this.elementRef)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -8
        }
      ])

    this.overlayRef = this.overlay.create({ positionStrategy })
  }

  ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.detach()
    }
  }
}
