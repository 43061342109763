import { ChangeDetectionStrategy, Component } from '@angular/core'
import { CommonModule } from '@angular/common'

@Component({
  selector: 'navix-loading-placeholder-form-field',
  standalone: true,
  imports: [CommonModule],
  template: ` <div class="flex flex-col gap-2">
    <div class="h-3 w-28 bg-slate-200 rounded-md"></div>
    <div class="h-7 w-full bg-slate-200 rounded-md"></div>
  </div>`,
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingPlaceholderFormFieldComponent {}
