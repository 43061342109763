import {
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  TemplateRef,
  inject
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { DialogRef } from '@angular/cdk/dialog'
import { NgIconComponent, provideIcons } from '@ng-icons/core'
import { heroXMarkSolid } from '@ng-icons/heroicons/solid'
import { DefaultDialogHeader } from './default-dialog-base.model'

/**
 * This component show a template
 * @example
 * <navix-dialog-base [defaultConfiguration]='defaultConfiguration' />
 * @example
 * <navix-dialog-base [defaultConfiguration]='defaultConfiguration'>
 *  <ng-template #header>
 *    <h1 class='customModalHeaderExists'>
 *      CustomModalHeader Mock Title
 *    </h1>
 *  </ng-template>
 * </navix-dialog-base>`
 */
@Component({
  selector: 'navix-dialog-base',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons({ heroXMarkSolid })],
  templateUrl: './dialog-base.component.html',
  styles: []
})
export class DialogBaseComponent {
  public readonly dialogRef = inject(DialogRef)

  @Input() defaultConfiguration: DefaultDialogHeader = { title: '' }

  @Output() closeDialog = new EventEmitter()
  @Input() loading = false
  @HostBinding('class')
  hostclasses = 'block px-4 sm:p-0 mx-auto w-[100vw]'

  @ContentChild('header') customModalHeaderTemplate:
    | TemplateRef<unknown>
    | undefined

  @ContentChild('headerButtons')
  customModalHeaderButtonsTemplate!: TemplateRef<unknown>

  @ContentChild('body')
  customModalBodyTemplate!: TemplateRef<unknown>

  @ContentChild('footer') customModalFooterTemplate:
    | TemplateRef<unknown>
    | undefined

  onClose() {
    this.closeDialog.emit()
    this.dialogRef.close()
  }
}
