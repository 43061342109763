<section
  class="flex flex-row gap-x-2 gap-y-2 py-3 items-center justify-center border-y-2 border-neutral bg-background text-default txt-body leading-4 sm:flex-wrap lg:flex-nowrap">
  <div
    class="flex flex-row items-center gap-x-2"
    *ngFor="let item of steps; let last = last; let i = index">
    <span class="rounded-full h-4 w-4 flex items-center justify-center">
      <span
        class="rounded-full border border-main h-4 w-4 flex items-center justify-center"
        [ngClass]="{ 'bg-primary': currentStep >= i + 1 }">
        <ng-icon
          name="heroCheckSolid"
          size="10"
          *ngIf="currentStep > i + 1"
          class="text-white" />
        <span
          class="rounded-full h-2 w-2 bg-white"
          *ngIf="currentStep === i + 1"></span>
      </span>
    </span>
    <span>{{ item.label }}</span>
    <span
      class="h-[1px] w-14 border-t border-dashed text-dashed"
      *ngIf="!last"></span>
  </div>
</section>
