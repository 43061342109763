<section
  class="grid grid-rows-[auto_minmax(0,_1fr)_auto] w-full py-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl">
  <header class="px-4 pb-4 flex items-center justify-between space-x-4">
    <ng-container
      *ngTemplateOutlet="
        customModalHeaderTemplate || defaulModalHeaderTemplate;
        context: { $implicit: defaultConfiguration }
      ">
    </ng-container>
    <ng-container
      *ngTemplateOutlet="
        customModalHeaderButtonsTemplate || defaulModalHeaderButtonsTemplate
      ">
    </ng-container>
  </header>

  <div>
    <ng-container *ngTemplateOutlet="customModalBodyTemplate"> </ng-container>
  </div>

  <footer
    class="flex flex-row flex-wrap items-center justify-end gap-x-2 px-4 pt-4">
    <ng-container
      *ngTemplateOutlet="
        customModalFooterTemplate || defaulModalFooterTemplate
      ">
    </ng-container>
  </footer>
</section>

<ng-template #defaulModalHeaderTemplate let-data>
  <div class="flex flex-col items-start gap-2">
    <h2 class="txt-title-medium text-default">{{ data.title }}</h2>
    <h3 class="txt-subtitle text-neutral-label" *ngIf="data.subtitle">
      {{ data.subtitle }}
    </h3>
  </div>
</ng-template>

<ng-template #defaulModalFooterTemplate>
  <button class="w-20 btn btn-outline-gray-500" (click)="onClose()">
    Close
  </button>
</ng-template>

<ng-template #defaulModalHeaderButtonsTemplate>
  <button (click)="onClose()" type="button" [disabled]="loading">
    <ng-icon
      name="heroXMarkSolid"
      size="20"
      class="text-icon-default"></ng-icon>
  </button>
</ng-template>
