/* eslint-disable @typescript-eslint/no-empty-function */

import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject
} from '@angular/core'
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR
} from '@angular/forms'

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule],
  selector: 'navix-input-with-icons',
  templateUrl: './input-with-icons.component.html',
  styleUrls: ['./input-with-icons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputWithIconsComponent,
      multi: true
    }
  ]
})
export class InputWithIconsComponent
  implements OnChanges, ControlValueAccessor
{
  private readonly cd: ChangeDetectorRef = inject(ChangeDetectorRef)
  inputText = ''

  @Input() placeholder = ''

  @Input()
  value: string = ''

  @Input()
  disabled = false

  @Output()
  changed = new EventEmitter<string>()

  @Input()
  @HostBinding('attr.tabIndex')
  tabIndex = 0

  @HostListener('blur')
  onBlur() {
    this.onTouch()
  }

  onChange: (newValue: string) => void = () => {}
  onTouch: () => void = () => {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['value']) {
      this.onChange(changes['value'].currentValue)
      this.writeValue(changes['value'].currentValue)
    }
  }
  writeValue(obj: string): void {
    this.value = obj
    this.inputText = obj
    this.cd.markForCheck()
  }
  registerOnChange(fn: (params: unknown) => void): void {
    this.onChange = fn
  }
  registerOnTouched(fn: () => void): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
    this.cd.markForCheck()
  }
  setValue(value: string) {
    if (!this.disabled) {
      this.value = value
      this.onChange(this.value)
      this.onTouch()
      this.changed.emit(this.value)
    }
  }

  handleChange() {
    this.setValue(this.inputText)
  }
}
