<navix-dialog-base [ngClass]="class">
  <ng-template #header>
    <div class="h-7 w-32 bg-slate-200 rounded-md animate-pulse"></div>
  </ng-template>

  <ng-template #body>
    <div class="grid grid-cols-12 gap-4 px-4 animate-pulse">
      <ng-container *ngFor="let row of _rowList">
        <article
          [ngClass]="getColSpanClass(row.columns)"
          *ngFor="let column of row.columnsList">
          <ng-template [ngTemplateOutlet]="field" />
        </article>
      </ng-container>
    </div>
  </ng-template>

  <ng-template #footer>
    <div class="flex flex-row gap-2 justify-end mt-2 animate-pulse">
      <div class="bg-slate-200 rounded-md h-9 w-24"></div>
      <div class="bg-slate-200 rounded-md h-9 w-24"></div>
    </div>
  </ng-template>
</navix-dialog-base>

<ng-template #field>
  <div class="flex flex-col gap-1">
    <div class="h-5 w-28 bg-slate-200 rounded-md"></div>
    <div class="h-8 w-full bg-slate-200 rounded-md"></div>
  </div>
</ng-template>
