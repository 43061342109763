import {
  ChangeDetectorRef,
  Component,
  Injector,
  OnInit,
  inject
} from '@angular/core'
import { ActivatedRoute, Router, RouterModule } from '@angular/router'
import { Dialog, DialogModule, DialogRef } from '@angular/cdk/dialog'
import { take } from 'rxjs'
import { DYNAMIC_DIALOG_CONFIG } from './dynamic-dialog-config.token'
import { ABSOLUTE_FEATURE_URL } from '@navix/utils/tokens'

@Component({
  standalone: true,
  imports: [RouterModule, DialogModule],
  template: ''
})
export class DialogOpenerWithRedirectionOnCloseComponent implements OnInit {
  private readonly dialog = inject(Dialog)
  private readonly router = inject(Router)
  private readonly changeDetector = inject(ChangeDetectorRef)
  private readonly route = inject(ActivatedRoute)
  private readonly dialogConfig = inject(DYNAMIC_DIALOG_CONFIG)
  private readonly injector = inject(Injector)

  protected dialogRef!: DialogRef

  ngOnInit(): void {
    this.openDialog()
    this.registerRedirectToListOnClose()
  }

  openDialog() {
    this.dialogRef = this.dialog.open(this.dialogConfig.component, {
      id: this.dialogConfig.id,
      injector: this.injector,
      data: { route: this.route },
      disableClose: true,
      maxHeight: '90vh',
      panelClass: 'overflow-auto'
    })
  }
  registerRedirectToListOnClose() {
    this.dialogRef.closed.pipe(take(1)).subscribe(() => {
      this.router.navigate(this.dialogConfig.redirectionCommand, {
        relativeTo: this.dialogConfig.relativeToParent
          ? this.route.parent
          : this.route,
        queryParamsHandling: 'preserve'
      })
      queueMicrotask(() => this.changeDetector.markForCheck())
    })
  }
}
