import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewChild
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { FieldStatusType, FieldStatusTypes } from './filed-status-types.model'

@Component({
  selector: 'navix-field-status[status]',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './field-status.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldStatusComponent {
  @Input({ required: true }) status!: FieldStatusType | boolean

  @Input() size: number = 8
  @ViewChild('spanContent') spanContent!: any

  get iconSize() {
    return `${this.size}px`
  }

  get statusClass() {
    if (typeof this.status === 'boolean')
      return (this.status as boolean)
        ? FieldStatusTypes.active
        : FieldStatusTypes.inactive

    return FieldStatusTypes[this.status]
  }

  get isInactive() {
    return this.status === 'inactive'
  }
}
