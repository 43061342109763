<div class="flex flex-row items-center gap-x-2 flex-nowrap">
  <span
    [class]="['rounded-full', 'border', statusClass]"
    [class.border-info]="isInactive"
    [style.width]="iconSize"
    [style.height]="iconSize">
  </span>
  <span #spanContent>
    <ng-content></ng-content>
  </span>

  <ng-container *ngIf="!spanContent.hasChildNodes()">
    {{ status ? 'Active' : 'Inactive' }}
  </ng-container>
</div>
