import { Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NgIconComponent, provideIcons } from '@ng-icons/core'
import { heroCheckSolid } from '@ng-icons/heroicons/solid'
import { Step } from './stepper.model'

@Component({
  selector: 'navix-stepper',
  standalone: true,
  imports: [CommonModule, NgIconComponent],
  providers: [provideIcons({ heroCheckSolid })],
  templateUrl: './stepper.component.html',
  styleUrls: ['stepper.component.scss']
})
export class StepperComponent {
  @Input() steps: Step[] = []
  @Input() currentStep = 1
}
