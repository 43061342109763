import { NgClass } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'navix-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss'],
  imports: [NgClass],
  standalone: true
})
export class SwitchComponent {
  @Input() value: boolean = false
  @Input() class?: string
  @Output() valueChange = new EventEmitter<boolean>()

  select(event: Event) {
    const checked = (event.target as HTMLInputElement).checked

    this.valueChange.emit(checked)
  }
}
