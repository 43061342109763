<div [navixTooltip]="tooltip">
  <button
    [disabled]="disabled"
    [ngClass]="useCustomIcon ? 'text-gradient' : 'btn btn-outline-main'"
    [cdkMenuTriggerFor]="menu"
    class="example-standalone-trigger"
    type="button"
    (cdkMenuOpened)="toggleOptions()"
    (cdkMenuClosed)="toggleOptions()">
    <ng-container *ngIf="!useCustomIcon; else iconTemplate">
      <span>{{ label }}</span>
      <ng-icon
        [name]="display ? 'heroChevronUpSolid' : 'heroChevronDownSolid'"
        size="20"></ng-icon>
    </ng-container>
  </button>
</div>
<ng-template #menu>
  <ul
    class="py-1 txt-subtitle bg-white divide-y divide-gray-100 rounded shadow w-44 text-table-label"
    cdkMenu>
    <li
      class="flex flex-row items-center break-words gap-x-2 py-2 px-3 border-b border-neutral border-solid last:border-b-0 cursor-pointer"
      [id]="option.key"
      [ngClass]="option.class"
      *ngFor="let option of options"
      (click)="onSelect(option)"
      cdkMenuItem>
      <ng-icon *ngIf="option.icon" [name]="option.icon" size="15"></ng-icon>
      <span>{{ option.label }}</span>
    </li>
  </ul>
</ng-template>
<ng-template #iconTemplate>
  <ng-icon name="heroEllipsisHorizontal" size="15"></ng-icon>
</ng-template>
