import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  Output,
  TemplateRef
} from '@angular/core'

@Component({
  selector: 'navix-form-screen',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './form-screen.component.html',
  styleUrls: ['./form-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormScreenComponent {
  @ContentChild('body') customBodyTemplate!: TemplateRef<unknown>
  @ContentChild('footer') customFooterTemplate: TemplateRef<unknown> | undefined
  @Output() cancelEvent = new EventEmitter()

  onCancel() {
    this.cancelEvent.emit()
  }
}
