import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  OnInit
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormControl } from '@angular/forms'
import { GenericItem } from '@navix/ui/common'
import { NgIconComponent, provideIcons } from '@ng-icons/core'
import { heroMagnifyingGlass, heroXMark } from '@ng-icons/heroicons/outline'
import { InputWithIconsComponent } from '../input-with-icons/input-with-icons.component'
import { PaginationBarComponent } from '../pagination-bar/pagination-bar.component'

@Component({
  selector: 'navix-checkbox-list',
  standalone: true,
  imports: [
    CommonModule,
    InputWithIconsComponent,
    NgIconComponent,
    ReactiveFormsModule,
    PaginationBarComponent
  ],
  templateUrl: './checkbox-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    provideIcons({
      heroMagnifyingGlass,
      heroXMark
    })
  ]
})
export class CheckboxListComponent implements OnInit {
  @HostBinding() class = 'h-full m-4'
  @Input() control?: FormControl<string[] | number[]> = undefined
  @Input() list: GenericItem[] = []
  @Input() title = ''

  readonly ITEMS_PER_CARD = 20
  currentPage = 1
  selected: string[] = []
  searchController = new FormControl<string>('', { nonNullable: true })

  ngOnInit(): void {
    if (this.control) {
      this.selected = this.control.value.slice().map(item => item.toString())
    }

    // Place selected items at the beginning
    this.list = this.list.slice().sort((a, b) => {
      const aIsPriority = this.selected?.includes(a.id.toString())
      const bIsPriority = this.selected?.includes(b.id.toString())
      const compareByName = a.name.localeCompare(b.name)

      if (aIsPriority && !bIsPriority) {
        return -1
      } else if (!aIsPriority && bIsPriority) {
        return 1
      } else {
        return compareByName
      }
    })
  }

  onCheckItem({ target: { checked } }: any, id: string) {
    if (checked) {
      this.selected.push(id.toString())
    } else {
      const index = this.selected.indexOf(id.toString())
      if (index !== -1) {
        this.selected.splice(index, 1)
      }
    }

    this.control?.markAsDirty()
    this.control?.setValue(this.selected)
  }

  onCheckAll({ target: { checked } }: any) {
    if (checked) {
      this.selected = this.list.flatMap(item => item.id.toString())
    } else {
      this.selected = []
    }

    this.control?.setValue(this.selected)
  }

  onSearchChange() {
    this.currentPage = 1
  }

  onSearchClear() {
    this.currentPage = 1
    this.searchController.reset()
  }

  handlePageChange(page: number) {
    this.currentPage = page
  }

  isItemChecked(id: string) {
    return this.selected.includes(id.toString())
  }

  get isAllChecked() {
    return this.list.length === this.selected.length
  }

  get filteredItems() {
    return this.list.filter(item =>
      item.name.includes(this.searchController.value)
    )
  }

  get filteredAndPaginatedItems() {
    return this.filteredItems.slice(
      (this.currentPage - 1) * this.ITEMS_PER_CARD,
      this.currentPage * this.ITEMS_PER_CARD
    )
  }
}
