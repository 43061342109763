<details
  [open]="open"
  #detail
  (toggle)="this.openChange.emit(detail.open)"
  class="col-span-2 bg-white custom-details rounded-none collapse collapse-arrow border"
  [class.h-full]="useFullHeight()">
  <summary
    class="collapse-title text-xs after:text-icon-default after:left-5 uppercase font-medium min-h-0 p-4 pl-12">
    <div class="flex flex-nowrap justify-between items-center">
      <ng-container *ngTemplateOutlet="customTitleLeft ?? defaultTitle" />
      <ng-container *ngTemplateOutlet="customTitleRight ?? defaultEmpty" />
    </div>
  </summary>
  <div class="collapse-content">
    <ng-container *ngTemplateOutlet="customBodyTemplate" />
  </div>
</details>

<ng-template #defaultEmpty></ng-template>
<ng-template #defaultTitle>
  {{ title }}
</ng-template>
