<div
  class="flex flex-row items-end justify-between gap-2 min-h-[2rem] border-b border-neutral overflow-hidden">
  <ng-container *ngIf="!loading; else loader">
    <div>
      <ng-container
        *ngTemplateOutlet="customLeftActionButtonsTemplate"></ng-container>
    </div>
    <div>
      <ng-container
        *ngTemplateOutlet="customRightActionButtonsTemplate"></ng-container>
    </div>
  </ng-container>
</div>
<ng-template #loader>
  <div class="w-full h-8 rounded-md animate-pulse bg-slate-200"></div>
  <div class="w-full h-8 rounded-md"></div>
  <div class="w-full h-8 rounded-md animate-pulse bg-slate-200"></div>
</ng-template>
