<div class="flex border bg-base-100 rounded-md border-neutral-500">
  <span class="inline-flex items-center px-3 text-lg text-gray-900">
    <ng-content select="[start]"> </ng-content>
  </span>

  <input
    type="text"
    class="bg-transparent border-none flex-1 w-full text-sm p-2.5 focus:border-transp arentfocus:shadow-none focus:ring-0"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [(ngModel)]="inputText"
    (ngModelChange)="handleChange()" />

  <span class="inline-flex items-center px-3 text-lg text-gray-900">
    <ng-content select="[end]"></ng-content>
  </span>
</div>
