<navix-dialog-base class="max-w-lg" [defaultConfiguration]="defaultHeader">
  <ng-template #body>
    <span class="px-4"> Are you sure you want to discard your changes? </span>
  </ng-template>
  <ng-template #footer>
    <button
      class="btn btn-outline-gray-500 w-24"
      id="discard-cancel-btn"
      (click)="onCancel()">
      Cancel
    </button>
    <button
      class="btn btn-main w-24"
      id="discard-confirm-btn"
      (click)="onDiscard()">
      Discard
    </button>
  </ng-template>
</navix-dialog-base>
