import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { CommonModule } from '@angular/common'
import { animate, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'navix-tooltip',
  standalone: true,
  imports: [CommonModule],
  template: `
    <span
      class="text-xs text-white px-2 block"
      @tooltip
      *ngFor="let text of textList">
      {{ text }}
    </span>
  `,
  styles: [
    `
      :host {
        display: block;
        @apply bg-[#616161] rounded-md m-2 py-2;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ]),
      transition(':leave', [animate(200, style({ opacity: 0 }))])
    ])
  ]
})
export class TooltipComponent {
  @Input() textList: string[] = ['']
  @Input() clickable = false
}
