<div
  class="h-full p-4 flex flex-col justify-between border rounded-md border-gray-300">
  <navix-input-with-icons
    placeholder="Search"
    [formControl]="searchController"
    (changed)="onSearchChange()"
    name="search">
    <ng-container start>
      <ng-icon name="heroMagnifyingGlass" />
    </ng-container>
    <ng-container end>
      <button
        class="flex"
        title="clear text"
        type="button"
        (click)="onSearchClear()">
        <ng-icon name="heroXMark" />
      </button>
    </ng-container>
  </navix-input-with-icons>
  <div class="min-h-[610px] pt-4 pb-1">
    <div class="mb-2 flex gap-1">
      <input
        type="checkbox"
        class="form-checkbox"
        [id]="title + '-all'"
        [checked]="isAllChecked"
        [disabled]="control?.disabled"
        (change)="onCheckAll($event)" />
      <label class="ml-1 text-sm" [for]="title + '-all'">
        Select All {{ title }}
      </label>
    </div>
    <div class="mb-2 flex gap-1" *ngFor="let item of filteredAndPaginatedItems">
      <input
        type="checkbox"
        class="form-checkbox"
        [id]="title + '-' + item.id"
        [checked]="isItemChecked(item.id)"
        [disabled]="control?.disabled"
        (change)="onCheckItem($event, item.id)" />
      <label class="ml-1 text-sm" [for]="title + '-' + item.id">
        {{ item.name }}
      </label>
    </div>
  </div>
  <navix-pagination-bar
    displayMode="right"
    [loading]="false"
    [itemsCount]="filteredItems.length"
    [itemsPerPageOptions]="[ITEMS_PER_CARD]"
    [itemsPerPage]="ITEMS_PER_CARD"
    [page]="currentPage"
    (pageChanged)="handlePageChange($event)" />
</div>
