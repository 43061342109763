import {
  Component,
  Host,
  Optional,
  Provider,
  Self,
  SkipSelf
} from '@angular/core'
import {
  DYNAMIC_DIALOG_CONFIG,
  DynamicDialodConfig
} from './dynamic-dialog-config.token'
import { ABSOLUTE_FEATURE_URL } from '@navix/utils/tokens'

/**
 * @description This function is used to provide the configuration for the dialog opener component.
 * @param data.component The component to be opened in the dialog.
 * @param data.id The id of the dialog. Defaults to the name of the component.
 * @param data.redirectionCommand The command to be executed on dialog close. Defaults to [{ outlets: { dialog: null } }].
 * @param data.relativeToParent Whether the redirection command should be executed relative to the parent route. Defaults to false.
 * @param data.useAbsoluteFeatureUrl Whether the redirection command should be executed relative to the absolute feature url. Defaults to false.
 * @example
 * //Custom Configuration
 *  provideDialogOpenerConfiguration({
        component: SomeComponenet,
        id: 'SomeId',
        redirectionCommand: [CUSTOM_ABSOLUTE_PATH, { outlets: { dialog: null } }],
        useAbsoluteRedirection: false
    })

* @example
    //Simple usage
      provideDialogOpenerConfiguration({
        component: SomeComponenet,
        useAbsoluteRedirection: true
    })
    // This will setup 
    {
        component: SomeComponenet,
        id: 'SomeComponenet',
        redirectionCommand: [ ABSOLUTE_FEATURE_URL, { outlets: { dialog: null } }],
        relativeToParent: false
    }
 * @see {@link DYNAMIC_DIALOG_CONFIG}
 * @see {@link DialogOpenerWithRedirectionOnCloseComponent}
 * @see {@link DynamicDialodConfig}
 * @returns The provider for the dialog opener component.
 * */
export function provideDialogOpenerConfiguration(
  data: Pick<DynamicDialodConfig, 'component'> &
    Partial<DynamicDialodConfig> & { useAbsoluteFeatureUrl?: boolean }
): Provider {
  const defaultRedirectionCommand = data.redirectionCommand ?? [
    { outlets: { dialog: null } }
  ]
  return {
    provide: DYNAMIC_DIALOG_CONFIG,
    useFactory: (
      abosluteFeatureUrl: typeof ABSOLUTE_FEATURE_URL
    ): DynamicDialodConfig => {
      const redirectionCommand =
        data.useAbsoluteFeatureUrl ?? false
          ? [abosluteFeatureUrl, ...defaultRedirectionCommand]
          : defaultRedirectionCommand
      console.log('redirectionCommand', abosluteFeatureUrl)
      return {
        component: data.component,
        id: data.id ?? data.component.name,
        redirectionCommand: redirectionCommand,
        relativeToParent: data.relativeToParent ?? false
      }
    },
    deps: [ABSOLUTE_FEATURE_URL]
  }
}
