import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { NgIconComponent, provideIcons } from '@ng-icons/core'
import { CdkMenuModule } from '@angular/cdk/menu'
import { DropdownMenuOption } from './dropdown-menu-option.model'
import {
  heroChevronDownSolid,
  heroChevronUpSolid
} from '@ng-icons/heroicons/solid'
import {
  heroBriefcase,
  heroEllipsisHorizontal,
  heroPencil,
  heroUser
} from '@ng-icons/heroicons/outline'
import { featherFileText } from '@ng-icons/feather-icons'
import { TooltipDirective } from '../tooltip/tooltip.directive'

@Component({
  standalone: true,
  imports: [CommonModule, NgIconComponent, CdkMenuModule, TooltipDirective],
  providers: [
    provideIcons({
      heroChevronUpSolid,
      heroChevronDownSolid,
      heroEllipsisHorizontal,
      featherFileText,
      heroBriefcase,
      heroUser,
      heroPencil
    }),
    TooltipDirective
  ],
  selector: 'navix-dropdown-button',
  templateUrl: './dropdown-button.component.html'
})
export class DropdownButtonComponent {
  @Input() label?: string = 'Actions'
  @Input() options: DropdownMenuOption[] = []
  @Input() useCustomIcon?: boolean = false
  @Input() item?: any
  @Input() disabled?: boolean = false
  @Input() tooltip: string = ''
  display: boolean = false

  toggleOptions(): void {
    this.display = !this.display
  }

  onSelect(option: DropdownMenuOption) {
    if (this.item) option.callback(this.item)
    if (!this.item) option.callback()
  }
}
