import { ComponentType } from '@angular/cdk/portal'
import { InjectionToken } from '@angular/core'

export type DynamicDialodConfig = {
  component: ComponentType<unknown>
  id: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  redirectionCommand: any[]
  relativeToParent: boolean
}

export const DYNAMIC_DIALOG_CONFIG = new InjectionToken<DynamicDialodConfig>(
  'DYNAMIC_DIALOG_CONFIG'
)
