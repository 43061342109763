import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  booleanAttribute,
  inject
} from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
  standalone: true,
  selector: '[navixCurrency]'
})
export class CurrencyDirective implements OnInit {
  @Input() formatValue: boolean = true
  @Input({
    transform: booleanAttribute
  })
  negative: boolean = false

  @Input({
    transform: booleanAttribute
  })
  formatZero: boolean = false

  readonly DECIMAL_LIMIT = 2
  readonly LAST_ATTR_NAME = 'data-last'

  private readonly element = inject(ElementRef)
  private readonly form = inject(NgControl)
  ngOnInit(): void {
    const element = this.element.nativeElement as HTMLInputElement
    const value = parseFloat(element.value)
    if (isNaN(value)) return
    if (value === 0 && !this.formatZero) return
    const valueToSet = this.getValueToSet(value)
    this.form.control?.setValue(valueToSet, { emitEvent: false })
  }

  @HostListener('change')
  restrict() {
    const element = this.element.nativeElement as HTMLInputElement
    const value = element.value
    const lastValue = element.getAttribute(this.LAST_ATTR_NAME) || ''

    const decimals = (value.split('.')[1] || '').length
    if (decimals > this.DECIMAL_LIMIT) this.form.control?.setValue(lastValue)

    element.setAttribute(this.LAST_ATTR_NAME, element.value)
  }

  @HostListener('blur', ['$event.target.value'])
  format(value: string) {
    const valueToSet = this.getValueToSet(Number(value))
    this.form.control?.setValue(valueToSet)
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value: string) {
    const valueNumber = Number(value)
    if (valueNumber === 0) {
      this.form.control?.setValue('')
    }
  }

  getValueToSet(value: number) {
    const valueToSet = isNaN(value)
      ? this.formatValue
        ? '0.00'
        : value
      : this.negative && value > 0
        ? (-1 * value).toFixed(2)
        : value.toFixed(2)
    return valueToSet
  }
}
