<label
  [ngClass]="class"
  class="inline-flex relative items-center cursor-pointer">
  <input
    type="checkbox"
    [value]="value"
    [checked]="value"
    class="sr-only peer"
    (change)="select($event)" />
  <div
    class="peer w-10 h-4 bg-gray-200 peer-checked:bg-blue-100 rounded-full peer-checked:after:translate-x-full after:content-[''] after:absolute after:-top-0.5 after:bg-gray-500 after:peer-checked:bg-main after:rounded-full after:h-5 after:w-5 after:transition-all"></div>
</label>
