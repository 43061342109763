/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @angular-eslint/no-output-native */
import { CommonModule } from '@angular/common'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  forwardRef,
  inject
} from '@angular/core'
import {
  ControlValueAccessor,
  FormsModule,
  NG_VALUE_ACCESSOR
} from '@angular/forms'
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor'

type T = string

@Component({
  selector: 'navix-input-editor',
  standalone: true,
  imports: [CommonModule, FormsModule, NgxEditorModule],
  styleUrl: './input-editor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <ngx-editor-menu *ngIf="showToolbar" [editor]="editor" [toolbar]="toolbar">
    </ngx-editor-menu>
    <ngx-editor
      [editor]="editor"
      [ngModel]="value"
      (ngModelChange)="setValue($event)">
    </ngx-editor>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEditorComponent),
      multi: true
    }
  ]
})
export class InputEditorComponent implements ControlValueAccessor, OnInit {
  editor!: Editor
  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4'] }],
    ['link'],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify']
  ]
  @Input()
  value: T = ''
  @Input()
  showToolbar: boolean = true
  @Input()
  disabled = false
  @Output()
  change = new EventEmitter<T>()

  private readonly cd = inject(ChangeDetectorRef)

  ngOnInit(): void {
    this.editor = new Editor()
  }

  onChange: (newValue: T) => void = () => {}
  onTouch: () => void = () => {}

  writeValue(obj: T): void {
    this.value = obj
    this.cd.markForCheck()
  }
  registerOnChange(fn: any): void {
    this.onChange = fn
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled
    this.cd.markForCheck()
  }

  setValue(value: T) {
    if (!this.disabled) {
      this.value = value
      this.onChange(this.value)
      this.onTouch()
      this.change.emit(this.value)
    }
  }
}
