import { DIALOG_DATA, Dialog, DialogRef } from '@angular/cdk/dialog'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { Router } from '@angular/router'
import { DefaultDialogHeader, DialogBaseComponent } from '../dialog-base'

@Component({
  selector: 'navix-dialog-discard',
  standalone: true,
  imports: [CommonModule, DialogBaseComponent],
  templateUrl: './dialog-discard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogDiscardComponent {
  defaultHeader: DefaultDialogHeader = { title: 'Discard Changes' }
  private readonly dialog = inject(Dialog)
  private readonly dialogRef = inject(DialogRef)
  private readonly dialogData = inject<{ path: string }>(DIALOG_DATA)
  private readonly router = inject(Router)

  onCancel() {
    this.dialogRef.close()
  }

  onDiscard() {
    if (this.dialogData) this.router.navigateByUrl(this.dialogData.path)
    this.dialog.closeAll()
  }
}
